import api from '@/service/api';
import App from '../main';
import Cookies from '@/service/cookie';

export default (store, router) => {

  api.interceptors.request.use(
    (config) => {

      const token = Cookies.get('token');
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      const otp = Cookies.get('otp');
      if (otp) {
        config.headers["Otp"] = otp;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  api.interceptors.response.use(
    function (response) {

      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      App.$Progress.fail();

      if (
        error.response.status === 401 &&
        originalRequest.url.includes("witness-api/refresh-access-token")
      ) {

        await store.dispatch("logout");

        Cookies.set('refreshTokenActive', true);

        router.push({
          name: 'login-refresh-token-expired'
        });

        return Promise.reject(error);
      } else if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        await store.dispatch("refreshToken");

        return api(originalRequest);
      } else if (error.response.status === 403 && !originalRequest._retry && error.response.data && error.response.data == 'Requires otp auth') {
        Cookies.remove('otp');
        router.push({
          name: 'otp-verify'
        }).catch(() => { });
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
};

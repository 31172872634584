<template>
  <div id="app" :class="{ 'new-case': $route.name == 'Nytt mål' }">
    <router-view/>
    <vue-progress-bar></vue-progress-bar>
    <vue-confirm-dialog></vue-confirm-dialog>
    <v-offline @detected-condition="amIOnline" />
  </div>
</template>


<script>
import Loader from './components/Loader';
export default {
	components: { Loader },
  mounted () {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish()
  },
  methods: {
    amIOnline(e) {
      if (!e) {
        this.$toast.warning('Just nu finns ingen internetuppkoppling', {
          position: 'top-center',
        });
      }
    }
  },
  created () {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {

      if (to.name !== undefined) {
        if (to.name == 'Nytt mål') {
          next()
          return;
        }
      }
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }

      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  }
};
</script>

import Vue from 'vue';
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class';
import store from '@/store/index';
import Cookies from '@/service/cookie';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: () => {
      if (store.getters.isLoggedIn) {
        return {
          name: 'Huvudmeny'
        }
      } else {
        return {
          name: 'login'
        }
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: {},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue')
  },
  {
    path: '/login',
    name: 'login-refresh-token-expired',
    meta: { requiresPermission: false },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'login.reset-password',
    meta: {},
    props: (route) => ({ token: String(route.params.token) }),
    component: () => import(/* webpackChunkName: "login" */ '@/views/User/ResetPassword.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Logout.vue')
  },
  {
    path: '/main-menu',
    name: 'Huvudmeny',
    meta: {},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/MainMenu.vue')
  },
  {
    path: '/testimony',
    name: 'Statistikinmatning',
    meta: {},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Testimony/TestimonyMainMenu.vue')
  },
  {
    path: '/testimony/registed',
    name: 'Tidigare Registrerat',
    meta: {},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Testimony/RegistedTestimony.vue')
  },
  {
    path: '/testimony/time/:id',
    name: 'Nedlagd tid',
    meta: {},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Testimony/TimeSpent.vue'),
    children: [
      {
        path: 'add/:timeTypeId',
        name: 'Lägg till tid',
        // meta: { requiresPermission: 'ticket-view-ticket' },
        props: (route) => ({ timeTypeId: Number(route.params.timeTypeId) }),
        component: () => import(/* webpackChunkName: "ticket-overview" */ '@/views/Testimony/AddTime.vue'),
      }
    ]
  },
  {
    path: '/testimony/new/:id',
    name: 'Nytt mål',
    props: (route) => ({ id: Number(route.params.id) }),
    component: () => import(/* webpackChunkName: "ticket" */ '@/views/Testimony/NewTestimony.vue'),
  },
  {
    path: '/testimony/edit/:id',
    name: 'Uppdatera mål',
    props: (route) => ({ id: Number(route.params.id) }),
    component: () => import(/* webpackChunkName: "ticket" */ '@/views/Testimony/TestimonySettings.vue'),
    children: [
      {
        path: 'persons',
        name: 'Registrerade personer',
        // meta: { requiresPermission: 'ticket-view-ticket' },
        props: (route) => ({ id: Number(route.params.id) }),
        component: () => import(/* webpackChunkName: "ticket-overview" */ '@/views/Testimony/ListPeople.vue'),
      },
      {
        path: 'add-person/:personId',
        name: 'Lägg till ny person',
        // meta: { requiresPermission: 'ticket-view-ticket' },
        props: (route) => ({ personId: Number(route.params.personId) }),
        component: () => import(/* webpackChunkName: "ticket-overview" */ '@/views/Testimony/AddPeople.vue'),
      },
      {
        path: 'testimony',
        name: 'Uppdatera målet',
        // meta: { requiresPermission: 'ticket-view-ticket' },
        props: (route) => ({ id: Number(route.params.id) }),
        component: () => import(/* webpackChunkName: "ticket-overview" */ '@/views/Testimony/EditTestimony.vue'),
      }
    ],
  },
  {
    path: '/otp-edit',
    name: 'otp-edit',
    meta: { requiresPermission: false },
    component: () => import(/* webpackChunkName: "login" */ '@/views/OtpEdit.vue'),
  },
  {
    path: '/otp-verify',
    name: 'otp-verify',
    meta: { requiresPermission: false },
    component: () => import(/* webpackChunkName: "login" */ '@/views/OtpVerify.vue'),
  },

  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "about" */ '@/views/notFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (savedPosition && to.name == 'Tidigare Registrerat') {
          resolve({
            savedPosition,
            behavior: 'smooth',
          })
        } else {
          resolve({
            x: 0,
            y: 0,
            behavior: 'smooth',
          })
        }
      }, 300)
    })
  }
})


router.checkLoginData = async function () {

  if (!Cookies.get('token')) {
    return;
  }

  if (store.getters.user) {
    return;
  }

  await Promise.all([
    store.dispatch('getUserInformation'),
    store.dispatch('getOptions'),
    store.dispatch('getInfoBoxes'),
    store.dispatch('setLoggedIn', Cookies.get('token')),
  ]).catch((error) => {
    throw error;
  });
}

/**
 * afterEach hook
 *
 * Remove loading indicator after routing is completed.
 */
router.afterEach((to, from) => { });

router.beforeEach((to, from, next) => {
  // Basic auth before permissions are loaded from API

  if (to.name == 'logout') {
    next();
    return;
  }

  const publicPages = ['login', 'login.reset-password'];
  const otpPages = ['otp-verify', 'otp-edit', 'logout'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = store.getters.isLoggedIn;
  const RefreshTokenInUse = Cookies.get('refreshTokenActive');
  const CourtSelected = store.getters.selectedCourt;

  if (otpPages.includes(to.name)) {
    next();

    return false;
  }

  router.checkLoginData().then(function () {
    if (authRequired && !loggedIn && !RefreshTokenInUse || typeof store.getters.permissions === 'undefined') {
      next('/login');
    } else {
      if (CourtSelected == null && to.name != 'Huvudmeny' && to.name != 'logout' && loggedIn) {
        next({ name: 'Huvudmeny' });
      } else if (!to.meta.requiresPermission) {
        next();
      } else {
        if (!store.getters.permissions.includes(to.meta.requiresPermission)) {
          next('/no-access');
        } else {
          next();
        }
      }
    }
  });

});

/* async function checkLoginData() {
  if (!Cookies.get('token')) {
    return;
  }
  if (!store.getters.user) {
    await store.dispatch('getUserInformation').then(async () => {
      await Promise.all([
        store.dispatch('getOptions'),
        store.dispatch('getInfoBoxes'),
      ]);
      store.dispatch('setLoggedIn', Cookies.get('token'));
    }).catch((error) => {
      throw error;
    });
  }
}
 */


export default router

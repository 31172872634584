import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios';
import VueCompositionAPI from '@vue/composition-api'
import interceptors from './service/interceptors';
import ReactiveSearch from '@appbaseio/reactivesearch-vue';
import Permissions from '@waju/vue-guard/dist/vue-guard';
import VueProgressBar from 'vue-progressbar'
// import VueToast from 'vue-toast-notification';
import Toast from "vue-toastification";
import VOffline from 'v-offline';
import { vfmPlugin } from 'vue-final-modal'
import Vuelidate from 'vuelidate'
import { i18n } from '@/plugins/i18n';
import { Trans } from '@/plugins/Translation';
import VModal from 'vue-js-modal';
import VueConfirmDialog from 'vue-confirm-dialog'

/* import the fontawesome core */
import { library, dom } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { faBoxArchive, faPlus, faCircleNotch, faX, faInfoCircle, faTrash, faSpinner, faRotate, faRing, faHeart, faCheck, faCircleCheck, faArrowUp, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
// import { } from '@fortawesome/free-brands-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Load bootstrap style and js
import "bootstrap"
import "@vueform/multiselect/themes/default.css"
// import 'vue-toast-notification/dist/theme-default.css';
import '@/assets/style/style.scss';

/* add icons to the library */

library.add(faClock, faBoxArchive, faPlus, faCircleNotch, faX, faInfoCircle, faTrash, faSpinner, faRotate, faRing, faHeart, faCheck, faCircleCheck, faArrowUp, faCircleXmark)
dom.watch();

/* Add component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

const toastOptions = {
  timeout: 2500,
  hideProgressBar: true,
  transition: "Vue-Toastification__fade"
  // You can set your default options here
};

/* Load plugins */
Vue.use(VueCompositionAPI)
Vue.use(ReactiveSearch);
// Vue.use(VueToast);
Vue.use(Toast, toastOptions);
Vue.use(VOffline);
Vue.use(vfmPlugin)
Vue.use(Vuelidate)
Vue.use(VModal);
Vue.use(VueConfirmDialog)
Vue.use(Permissions, {
  router, errorRoute: '/403'
});

/* Trans settings */
Vue.prototype.$http = Axios;
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

Vue.prototype.$to = (object, label) => {
  var field = label + '_' + i18n.locale;
  return object[field] ? object[field] : object[label];
}
Vue.prototype.$label = () => {
  return i18n.locale == 'en' ? 'label_en' : 'label';
}

Vue.prototype.$field = (field) => {
  return i18n.locale == 'en' ? field + '_en' : field;
}


const options = {
  color: '#406c5d',
  failedColor: '#d54338',
  thickness: '5px',
  transition: {
    speed: '0.1s',
    opacity: '0.6s',
    termination: 300
  },
  position: 'fixed',
  autoRevert: true,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, options)

/* Simple Directive used for permissions */
Vue.directive('can', {
  bind: function (el, binding, vnode) {
    if (!store.getters.permissions.includes(binding.value)) {
      el.style.display = 'none';
    }
  },
});

// Axios interceptors
interceptors(store, router);

// Init the app
export default new Vue({
  router,
  store,
  i18n,
  permissions() {
    return this.$store.getters.permissions;
  },
  ...App
}).$mount('#app')

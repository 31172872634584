import Vue from 'vue';
import Vuex from 'vuex'
import BojAPI from '@/api/boj'
import createPersistedState from "vuex-persistedstate";
import Cookies from '@/service/cookie';

Vue.use(Vuex);

function initialState() {
  return {
    options: {},
    selectedCourt: null,
    loggedIn: false,
    user: {},
    permissions: {},
    infoBoxes: {},
    formFinish: false,
    otpOptions: {},
  }
}

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: initialState,
  mutations: {

    SET_COURT(state, data) {
      state.selectedCourt = data;
    },

    SET_OPTIONS(state, data) {
      state.options = data
    },

    SET_USER(state, data) {
      state.user = data;
      state.permissions = data.permissions;
    },

    SET_INFO_BOXES(state, data) {
      state.infoBoxes = data;
    },

    SET_FORM_FINISH(state, data) {
      state.formFinish = data
    },

    async AUTH_LOGIN(state, token) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })

      state.loggedIn = true;


      if (token == null) {
        Cookies.clearCookies();
      } else {
        Cookies.set('token', token);
        await this.dispatch("getOtpOptions");
      }
    },
    AUTH_LOGOUT(state) {
      //Cookies.clearCookies();
      Cookies.remove('token');
      localStorage.clear();
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
      
      // Also logout on Minerva site
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('sender') != 'minerva') { 
        var f = document.createElement('iframe');
        f.src = process.env.VUE_APP_FRONTEND_LINK + '/logout?sender=witness';
        f.style.display = 'none';
        document.body.appendChild(f);
      }
      

    },

    SET_OTP_OPTIONS(state, payload) {
      state.otpOptions.otp_channel = payload.otp_channel;
      state.otpOptions.otp_email = payload.otp_email;
      state.otpOptions.otp_sms = payload.otp_sms;
    },

  },
  actions: {

    setCourt({ commit }, court) {
      commit('SET_COURT', court);
    },

    setOtpOptions({ commit }, data) {
      commit('SET_OTP_OPTIONS', data);
    },


    getOtpOptions({ commit }) {
      return BojAPI.getOtpOptions().then((response) => {
        commit('SET_OTP_OPTIONS', response.data);
      });
    },

    getOptions({ commit }) {
      return BojAPI.getOptions()
        .then(response => {
          commit('SET_OPTIONS', response.data)
        })
        .catch(error => {
          throw error;
        })
    },

    getUserInformation({ commit }) {
      return BojAPI.getUserInformation()
        .then((response) => {
          commit('SET_USER', response.data);
          // Sentry.setUser({ email: response.data.user.email });
        })
        .catch((error) => {
          return;
        });
    },

    setFormFinish({ commit }, type) {
      commit('SET_FORM_FINISH', type)
    },

    /**
     *
     * @param commit
     * @returns {*}
     */
    getInfoBoxes({ commit }) {
      return BojAPI.getInfoBoxes().then((response) => {
        commit('SET_INFO_BOXES', response.data);
      });
    },

    login({ commit }, user) {
      return BojAPI.login(user.username, user.password)
        .then((response) => {
          commit('AUTH_LOGIN', response.data.access_token);
        })
        .catch((error) => {
          Cookies.remove('token');
          throw error;
        });
    },

    logout({ commit }) {
      return BojAPI.logout()
        .then(() => {
          commit('AUTH_LOGOUT');
        })
        .catch(() => {

        })
    },

    setLoggedIn({ commit }, token) {
      commit('AUTH_LOGIN', token);
    },

    refreshToken({ commit }) {
      return BojAPI.refreshAccessToken().then(response => {
        commit('AUTH_LOGIN', response.data.access_token);
      });
    },
  },
  getters: {
    options: (state) => state.options,
    otpOptions: (state) => state.otpOptions,
    user: (state) => {
      return state.user.user ?? null
    },
    getInfoBox: (state) => (field) => {
      return state.infoBoxes.find((infoBox) => infoBox.field === field);
    },
    isLoggedIn: (state) => state.loggedIn,
    selectedCourt: (state) => state.selectedCourt,
    permissions: (state) => state.permissions,
    formFinish: (state) => state.formFinish,
  },
  modules: {
  }
})

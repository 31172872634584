import api from '@/service/api';

export default {

  /**
   * Get InfoBoxes
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  getInfoBoxes() {
    return api.get('/api/info/boxes');
  },

  /**
   * Reset password E-mail
   *
   * Sends an e-mail containing link to
   * reset the password for the given user.
   *
   * @param email
   * @returns {Promise<AxiosResponse<any>>}
   */
  sendResetPasswordMail(email) {
    return api.post('/witness-api/forgot-password', { email: email });
  },

  /**
   * Send a password reset request.
   *
   * Contains new password, associated e-mail
   * and verification token.
   *
   * @param email
   * @param password
   * @param token
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  sendResetPasswordRequest(email, password, token) {
    return api.post('/witness-api/reset-password', {
      email: email,
      password: password,
      password_confirmation: password,
      token: token,
    });
  },

  /**
   * Creates a testimony row
   * @param {id} testimony
   * @returns {Promise<AxiosResponse<any>>}
   */
  createTestimony(testimony) {
    return api.post('/witness-api/testimony/create', testimony);
  },

  /**
   *
   * @param {id} testimony_id
   * @returns
   */
  getTestimony(testimony_id) {
    return api.get('/witness-api/testimony/' + testimony_id);
  },

  /**
  *
  * @param {id} testimony_id
  * @returns
  */
  deleteTestimony(testimony_id) {
    return api.delete('/witness-api/testimony/' + testimony_id);
  },

  /**
   *
   * @param {id} person_id
   * @returns
   */
  getPerson(person_id) {
    return api.get('/witness-api/testimony/person/' + person_id);
  },

  /**
  *
  * @param {id} testimony_id
  * @returns
  */
  addTestimonyPerson(testimony_id) {
    return api.post('/witness-api/testimony/' + testimony_id + '/add-person');
  },

  /**
   *
   * @param {id} person_id
   * @returns
   */
  removeTestimonyPerson(person_id) {
    return api.delete('/witness-api/testimony/persons/' + person_id + '/remove-person');
  },

  /**
   * Update a relationship field on the testimony
   *
   * @param testimony
   * @param field
   * @param ids
   * @returns {*}
   */
  updateTestimonyField(testimony, data) {
    return api.post('/witness-api/testimony/' + testimony.id + '/field', data);
  },

  /**
   * Update a relationship field on the testimony
   *
   * @param person
   * @param field
   * @param ids
   * @returns {*}
   */
  updateTestimonyPersonField(person, data) {
    return api.post('/witness-api/testimony/persons/' + person.id + '/field', data);
  },

  /**
   *
   * @param {id} time_id
   * @returns
   */
  getTestimonyTime(time_id) {
    return api.get('/witness-api/testimony/time/' + time_id);
  },

  /**
  *
  * @param {id} time
  * @returns
  */
  createTestimonyTime(field, ids) {
    return api.post('/witness-api/testimony/time/create', { field, ids });
  },

  /**
  *
  * @param {id} time
  * @returns
  */
  updateTestimonyTime(time, data) {
    return api.post('/witness-api/testimony/time/' + time.id + '/field', data);
  },

  /**
  *
  * @param {id} time
  * @returns
  */
  removeTestimonyTime(time, timeType) {
    return api.delete('/witness-api/testimony/time/' + time + '/type/' + timeType + '/remove-single-time');
  },


  newOtp() {
    return api.post('/api/otp');
  },

  getOtpOptions() {
    return api.get('/api/otp/options');
  },

  verifyOtp(token) {
    return api.post('/api/otp/verify', {
      token: token,
    });
  },

  updateOtpEmail(email) {
    return api.post('/api/otp/email', {
      otp_email: email
    });
  },

  updateOtpSms(sms) {
    return api.post('/api/otp/sms', {
      otp_sms: sms
    });
  },

  updateOtpChannel(channel) {
    return api.post('/api/otp/channel', {
      otp_channel: channel
    });
  },

  /**
   * Retrieve current authenticated user
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  getUserInformation() {
    return api.get('/witness-api/user');
  },

  /**
   * Retrieve options for tickets
   * @returns {*}
   */
  getOptions() {
    return api.get('/witness-api/options');
  },

  /**
   * Attempt login
   *
   * @param username
   * @param password
   * @returns {*}
   */
  login(username, password) {
    return api.post('/witness-api/login', {
      email: username,
      password: password,
    });
  },

  /**
   * Attempt logout
   *
   * @param username
   * @param password
   * @returns {*}
   */
  logout(username, password) {
    return api.post('/witness-api/logout', {
      email: username,
      password: password,
    });
  },

  /**
   * Attempt get refresh token
   *
   * @param username
   * @param password
   * @returns {*}
   */
  refreshAccessToken(username, password) {
    return api.post('/witness-api/refresh-access-token', {
      email: username,
      password: password,
    });
  },
};
